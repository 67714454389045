<template>
  <div>
    <div class="has-text-centered-mobile">
      <a
        href="/"
        class="button is-lessmess-app has-text-primary is-large is-size-6"
        @click.prevent="openModal"
      >
        {{ title }}
      </a>
    </div>
    <ClientOnly>
      <o-modal v-model:active="isComponentModalActive" teleport>
        <div class="modal-card has-text-weight-bold" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">
              Download
            </p>
          </header>
          <section class="modal-card-body">
            <onboarding-app-buttons />
            <div class="has-text-centered mb-4 mt-6">
              <nuxt-link
                :to="{ name: 'user-register' }"
                class="button is-link is-outlined"
                @click="isComponentModalActive = false"
              >
                <span class="icon is-small">
                  <LoginIcon />
                </span>
                <span>Registrieren (vor dem Download)</span>
              </nuxt-link>
            </div>
            <div class="has-text-centered mb-4">
              <nuxt-link
                :to="{ name: 'user-login' }"
                class="button is-link is-outlined"
                @click="isComponentModalActive = false"
              >
                <span class="icon is-small">
                  <RegisterIcon />
                </span>
                <span>Login (bereits registriert)</span>
              </nuxt-link>
            </div>
          </section>
        </div>
      </o-modal>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import RegisterIcon from '~/assets/svgs/icons/ic24-edit.svg?component'
import LoginIcon from '~/assets/svgs/icons/ic24-user.svg?component'

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
})

const { isMobile, isIos, isAndroid, isDesktop } = useDevice()

const isComponentModalActive = ref(false)

function openModal() {
  if (isIos) {
    window.location.href = 'https://apps.apple.com/ch/app/lessmess/id1459787403'
    return
  }
  if (isAndroid) {
    window.location.href = 'https://play.google.com/store/apps/details?id=ch.extramedica.lezzmezz'
    return
  }

  if (isMobile) {
    window.location.href = '/'
  }

  if (isDesktop) {
    isComponentModalActive.value = true
  }
}
</script>

<style scoped lang="scss">
.modal-card-body {
  .buttons {
    flex-direction: column;
    min-width: 400px;
    ::v-deep(a) {
      margin: 0;
      img {
        max-height: inherit;
      }
    }
  }

  svg {
    path {
      fill: $sky-blue;
    }
  }
}
</style>
