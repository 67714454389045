<template>
  <div class="buttons mt-6 mobile-centered">
    <a
      href="https://apps.apple.com/ch/app/lessmess/id1459787403"
      target="_blank"
      class="app-button"
    >
      <img
        alt="App Store Button"
        src="~/assets/svgs/buttons/app-store-black-original.svg?url"
        data-not-lazy
      >
    </a>
    <a
      class="app-button last-button"
      href="https://play.google.com/store/apps/details?id=ch.extramedica.lezzmezz"
      target="_blank"
    >
      <img
        alt="Google Play"
        src="~/assets/svgs/buttons/google-play-black-original.svg?url"
        data-not-lazy
      >
    </a>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
.app-button {
  margin-right: 1rem;
  &.last-button {
    margin-right: 0;
  }
}

.mobile-centered {
  .app-button {
    margin-right: 0;
    @include tablet {
      margin-right: 1rem;
    }
    &.last-button {
      margin-right: 0;
    }
  }
}
</style>
